import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import React from "react";
import Typography from "@mui/material/Typography";

export default function DisclaimerFooter() {
    return (<Paper sx={{
        marginTop: 'calc(10% + 60px)',
        position: 'sticky',
        bottom: 0,
        width: '100%'
    }}
        component="footer"
        elevation={0}>
        <React.Fragment>
            <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body"
                color="text.warning"
            >
                <Alert severity="info">* Individual results may vary</Alert>

            </Typography>
        </React.Fragment>
    </Paper>
    )
}