import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import React from "react";
import { createRoot } from "react-dom/client";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <div style={{ height: "100vh" }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </div>
  </React.StrictMode>,
);
