import Box from '@mui/material/Box';
import BusinessInfo from './BusinessInfo';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DFooter from "./DisclaimerFooter";
import React from "react";
import Typography from '@mui/material/Typography';

export default function Home() {
    const subheader =
        <BusinessInfo />;

    const title = <Typography variant="h4">
        San Diego's Premier Center for Cleansing, Detoxification and Rejuvination Therapies
    </Typography>
    return (
        <React.Fragment>
            <Card
                sx={{ minHeight: "90%" }}
                elevation={0}
            >
                <CardHeader
                    title={title}
                    subheader={subheader}
                    sx={{ textAlign: "center" }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            <table align="center" cellSpacing="0" cellPadding="10" border="1" bordercolor="#2a1770" bgcolor="#ffffff">
                                <tbody>
                                    <tr>
                                        <td align="left">
                                            <p>
                                                <img src="./images/irina.jpg" alt="Irina Popov" align="left" hspace="10" />

                                                <b>Welcome to San Diego Body Cleanse, Inc!</b>
                                            </p>
                                            <p>Our company's main goal is promoting your general health and well-being. With that in mind we offer truly customized treatments based on what you really want and need at a price you can afford.</p>
                                            <p>Irina Popov is the President of our company. Irina's passion for health and well-being led her in 2005 to open <i>San Diego Body Cleanse, Inc</i>.</p>
                                            <p>She is a Certified Colon Hydrotherapist, and a member of the International Association for Colonhydrotherapy.</p>
                                            <p>She originally received an engineering degree from one of the most prestigious technical schools, and then became educated in the field of natural medicine, body cleansing and detoxing. This combined training helps her to better understand the complicated processes that operate in our body along with finding the answers to what our body needs.</p>
                                            <p><img src="./images/irinaski-sm.jpg" alt="Irina Popov" align="right" hspace="10" />Irina has helped numerous people with a wide range of problems. Clients report feeling younger, fitter and healthier and having a new zest for life, helping them to enjoy each day and deal with the many problems that life presents.</p>
                                            <p>Holding a firm belief that every problem has an answer or can at least be alleviated and changed, she has achieved great results. As an experienced therapist, Irina is keen to emphasize the positive psychological as well as physical benefits of Colonic Hydrotherapy.</p>
                                            <p>Today she wants to share her knowledge with You!</p>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </Typography>
                    </CardContent>
                </Box>
            </Card>
            <DFooter />
        </React.Fragment>
    )
}