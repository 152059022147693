import { Box, Typography } from "@mui/material";

import React from "react";

export default function BusinessInfo() {
    return (
        <Box sx={{ minWidth: "600" }}>
            <ul style={{ listStyleType: "none", paddingInlineStart: 0 }}>
                <li><Typography variant="h6">San Diego Body Cleanse</Typography></li>
                <li>De Anza Medical Center</li>
                <li>3737 Moraga Avenue, Suite A2,</li>
                <li>San Diego, CA 92117</li>
                <li>Email: <a href="mailto:sandiegobodycleanse@yahoo.com">sandiegobodycleanse@yahoo.com</a></li>
                <li><a href="tel:619-218-8772">619-218-8772</a></li>
            </ul>
        </Box>
    );
}