import * as React from 'react';

import { Box, Button } from "@mui/material";
import {
    Route,
    Routes,
    useNavigate
} from "react-router-dom";

import About from "./About";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupTwoTone from '@mui/icons-material/GroupTwoTone';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import Homepage from "./Home";
import PropTypes from 'prop-types';
import Testimonials from "./Testimonials";
import Toolbar from '@mui/material/Toolbar';
import { isMobile as isMobileAgent } from 'react-device-detect';
import useScrollTrigger from '@mui/material/useScrollTrigger';

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function App(props) {
    const [windowDimension, setWindowDimension] = React.useState(null);
    const navigate = useNavigate();
    React.useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    React.useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const onHomeClick = (e) => {
        navigate('/', { replace: true });
    }

    const onTestimonialsClick = () => {
        navigate('/testimonials', { replace: true });
    }

    const onAboutClick = () => {
        navigate('/about', { replace: true });
    }



    const NavRoutes = () => {
        return (
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/about" element={<About />} />
            </Routes>
        )
    }

    const isMobile = windowDimension <= 768 || isMobileAgent;

    const NavBar = (props) => {
        return (
            <AppBar {...props}>
                <Toolbar>
                    {/* logo 100x58 */}
                    <Box component="img" src="./images/logo2-sm.jpg" alt="Logo"
                        sx={{
                            maxHeight: 44, //75%
                            maxWidth: 75,
                            display: { xs: 'none', md: 'flex' },
                            borderRadius: 3,
                            opacity: 0.7
                        }} />
                    <Button
                        onClick={onHomeClick}
                        startIcon={<HomeOutlined />}
                        sx={{ mx: 'auto', color: 'white', display: 'flex' }}
                    >
                        Home
                    </Button>
                    <Button
                        onClick={onTestimonialsClick}
                        startIcon={<GroupTwoTone />}
                        sx={{ mx: 'auto', color: 'white', display: 'flex' }}
                    >
                        Testimonials
                    </Button>
                    <Button
                        onClick={onAboutClick}
                        startIcon={<FmdGoodOutlinedIcon />}
                        sx={{ mx: 'auto', color: 'white', display: 'flex' }}
                    >
                        About
                    </Button>
                </Toolbar>
            </AppBar>
        )
    }


    return (
        <React.Fragment>
            <CssBaseline />
            {isMobile ? (
                <React.Fragment>
                    <Container sx={{ height: "90%" }} disableGutters>
                        <Box sx={{ my: 2, height: "100%" }}>
                            <NavRoutes />
                        </Box>
                    </Container>
                    <Toolbar />
                    <ElevationScroll {...props}>
                        <NavBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }} />
                    </ElevationScroll>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <ElevationScroll {...props}>
                        <NavBar position="fixed" color="primary" sx={{ top: 0, bottom: 'auto' }} />
                    </ElevationScroll>
                    <Toolbar position="sticky" />
                    <Container sx={{ height: "90%" }} disableGutters>
                        <Box sx={{ my: 2, height: "100%" }}>
                            <NavRoutes />
                        </Box>
                    </Container>
                </React.Fragment>)
            }
        </React.Fragment>

    );
}
