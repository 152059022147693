import * as React from 'react';

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export default function Testimonials() {

    const disclaimer = <ListItem alignItems="flex-start">

        <ListItemText
            primary={
                <React.Fragment>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body"
                        color="text.warning"
                    >
                        <Alert severity="info">* Individual results may vary</Alert>

                    </Typography>
                </React.Fragment>
            }
        />
    </ListItem>;

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {disclaimer}
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Clark T." src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Clark T., RN"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Miss Irina,<br />
                                I just wanted to tell you thank you so much from the bottom of my heart and also share with others what a Blessing you really are.<br />
                                I have had colonic irrigation before, but never such a calming and educational experience as this one.<br />
                                From the moment I lay on your table, I was at ease. As a Registered Nurse, I thought I knew everything there was to know about the gastrointestinal system, but you taught me so much more. And you taught me the importance of balance, in both your physical and spiritual life to promote healing and wellness.<br />
                                I felt so at ease with you. You were so kind, gentle, and re-assuring. Your business environment is so clean and soothing. I felt like I was almost talking with my mother. I had no pain, and no anxiety. In fact, I couldnt believe the whole process was over! That's how relaxed I was!<br />
                                &emsp;Through your colonic irrigations and advice about diet and probiotics, I have already lost 6.5 lbs in 1 week! I no longer have a bloated feeling anymore, I have more energy, my skin has a "glow to it", and I just feel so light and an overrall sense of well-being! All of these are so very important to me, as I am 28 years old and appearance is important.<br />
                                Another advantage to you is that your colonic irrigations are so affordable! And that is a huge plus in this ever-changing economy!<br />
                                Once again Miss Irina, i just wanted everyone to know how wonderful you are and what a change your colonic treatments have made in my life! Looking forward to seeing you very soon!<br />
                                God Bless you<br />
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Connie R." src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Connie R."
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;My dear Irina,<br />
                                Thank you for your patience with my body! It took us a while, and you stood with me. Now I feel emotionally up and physically lighter and healthier.<br />
                                I've gone to many colon hydrotherapists - you are far and beyond the BEST! Most skilled, most caring with many extras - body work, reflexology, etc.<br />
                                I refer all my clients and friends to you.<br />
                                I wish you abundance in every form with your new, lovely location.<br />
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Josie L." src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Josie L."
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;I have done colonics before, but this time I have felt so much more energy and been more focused. My body has reacted in a more normal function. My skin, eyes, hair, and all my body feel and look so much healthier than before. Now I feel I want to continue to take care of my body.<br />
                                Thank you so much for your passion to help people. It has truly changed my life.<br />
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Christie M." src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Christie M."
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Irina,<br />
                                Thank you. Thank you. Thank you.<br />
                                I was scared to death to do this. I suffered with IBS for 20 years! I didn't realize how much the bloating gas weighed me down, not able to eat some things, always worry about gas, etc., etc.<br />
                                I tried so many products - fiber, etc., always working on cleansing and still constipated.<br />
                                This is the only thing that works for me.<br />
                                I am 58 years old. I have so much energy and feel so young. My tummy is flat and I am not bloated. Irina is a very good lady. She puts her whole heart into this type of healing. She is a hands-on therapist. I went to another lady for 3 colonics and stopped because I could not relax. Irina relaxes me. She is the BEST.<br />
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Liana" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Liana"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;I am 25 years old and i have so many noticeable differences in my body after getting a series of colonics.<br />
                                I have lost 35lbs and I am in the best shape of my life! My skin is clear for the first time since i was a teenager, I have no aches and pains in my back and shoulders, my eyes are clear and I can see and hear better.<br />
                                My body is relaxed and feeling good, my mind is clear and best of all, I have a lot of energy. It is amazing to see such a difference in my life and well-being.<br />
                                I did not realize how much an unhealthy colon can affect my health and my mood, until seeing Irina.<br />
                                She helped me so much with my good health and it has changed how I feel and changes the decisions I make for my body. The benefits of becoming clean on the inside will have an everlasting effect of showing it on the outside. Thanks Irina! You've been a pleasure.<br />
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Anand V." src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Anand V."
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Irina Popov is an excellent Colon Therapist who has been helping me recover from colon problems for the past seven months. I am 70 years old, and i have had both intestinal and digestive difficulties for many years and have seen a number of therapists. They all helped some but none were as loving and caring, effective and knowledgeable as Irina.<br />
                                For the first time my colon, and possibly my digestive system are actually approaching normal; something i thought would never happen.<br />
                                I am very grateful to Irina for all the loving care and help she has given to me- in many ways.<br />
                                I am happy to recommend her to anyone, as her expertise in the field of Colonics<br />
                                is far beyond any others i have experienced.<br />
                                In Peace and Love,<br />
                                Anand V.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Nathalee Naude" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Nathalee Naude"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;I am 39 years old and have been seeing colon therapists for the past 14 years.<br />
                                It all began when I fell seriously ill with a colon disease, being hospitalized on and off for a period of five years. My road to recovery began with a change in diet and proper colon care.<br />
                                &emsp;Today, I am healthier than ever and continue with occassional colonics as a maintenance for optimal health.<br />
                                When I moved to La Jolla, CA a few months ago I searched for a colon therapist that I could feel comfortable with.<br />
                                I'm extremely particular when it comes to anyone who will cleanse my colon. I'm the one who asks a million and one questions inquiring about sterility,<br />
                                water purification, technique, etc. and I have to say that I was blown away when I met Irina.<br />
                                &emsp;Out of the many therapist I have been to over the years there has never been one who has given the amount of time in education and the quality of care that I received from Irina.<br />
                                Even though I am well educated on the process and benefits of a colonic I was more than newly inspired by her presentation. Her expertise is evident in her attention to detail,
                                the sensitivity she has to me as a client and her obvious mastery in operating the machine in order to produce spectacular results.
                                I had neglected myself for a bit and came to Irina with a sluggish colon which was rejuvenated within two visits! She is hands down the best I've ever experienced and I am so grateful to now have her in my life.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Lakeside" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Lakeside"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Just wanted to say I was nervous in the beginning but Miss Irina was very professional, very calming, and incredibly informative. I appreciated the care she took to not only assure us that we were in good hands but she also educated us on how to live a healthier lifestyle. It would be foolish to cleanse and then fill up with junk all over again. I am thankful for the care and professionalism that was extended at San Diego Body Cleanse.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Marine S." src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Marine S."
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;She really is the best in San Diego. Irina truly cares about her clients; she wants to help and teach you ways to improve your health. I have been going to her for over six months now and I've seen amazing results. My overall health and digestive problem has improved. I have more energy now than I ever did before. After every session, I feel like a new person. You are in good hands with Irina.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="TK" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="TK"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;San Diego Body Cleanse is a great place!! Irina is so caring and loves to make my visit comfortable and knowledgeable. Each visit is a learning experience. I've have been nervous about getting treatments and this place is soo relaxing and you feel at ease with Irina she really cares about her clients. I have more energy and feel super. Thanks to Irina!!!
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Stephanie" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Stephanie"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Other than San Diego Body Cleanse, I have been to two other places. This place is the best. It is very comfortable, and I have received a lot of information about being healthier and taking better care of my body. Since changing my diet and getting hydrotherapy, I have lost almost 30 pounds. But more importantly, I feel healthier and happier. Irina at San Diego Body Cleanse is the warmest person I have ever met. She truly cares about people and their health.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Sandra" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Sandra"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Jun 25, 2011<br />
                                Best Colon Hydrotherapy in San Diego! I've been going to Irina's place for colon cleansing regularly since 2009. My health improved a lot since then! Irina is a very caring person. She has great knowledge about what she's doing. Besides colonics, she also educates me about how to improve my diet and long-term health. Many thanks to Irina!
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Laura" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Laura"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Irina deserves 10 stars! Colon Hydrotheraphy is such a personal treatment so you have to find a therapist like Irinia that you trust and respect and who is caring and supportive. What sets her apart is that she helps resolve your short-term health challeges with the hydrotheraphy while educating you on the steps you can take to improve your long-term health. Nevertheless, she doesn't lecture you or make you feel guilty. She simply provides the information and encourages you to consider things you may never have thought about previously.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Minnie" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Minnie"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;Irina is a wealth of information. She has helped me transform my health by educating me about how to eat healthy and make lifestyle choices that support that. She is passionate about helping people to feel better. Having gone to other colon hydrotherapists, I can say that I find her to be the absolute best.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Nicholas" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Nicholas"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;
                                Irina has been an angel for my family. My father has had terrible eating habits all his life. He was always irritable and moody. We found out that hes had problems with constipation. We have tried every kind of medicine to help him but they were all ineffective for him. After a few treatments with Irina, he almost cried because he was able to actually get some relief after all these years.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Nam" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Nam"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;
                                I was skeptical at first but after a few referrals i finally decided to go through with the colonictreatment. I must admit the results were shocking from what you are able to see that comes out of you that has been laying dormant for years of bad eating habits, and now i feel so much healthier thanks to Irinia.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Sandman" src="images/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Sandman"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <br />&emsp;
                                I have never met a person so dedicated to her profession. Irina is the best and she educates you through the entire process. I never knew how much "stuff" stays in your body, even weeks after you eat it... Definitely recommend her services for any age.
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Google Reviews" src="images/icons8-google-48.png" />
                </ListItemAvatar>
                <ListItemText
                    primary="Read more reviews"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                <a href="https://g.page/r/CabpjfQ0dbM9EBE">On Google</a>
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            {disclaimer}
        </List>
    );
}
