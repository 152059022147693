import Box from "@mui/material/Box";
import BusinessInfo from "./BusinessInfo";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from "@mui/material/CardHeader";
import React from "react";
import Typography from "@mui/material/Typography";

export default function About() {
    const title = <Typography variant="h6">                    If you would like any further information about colon hydrotherapy or foot detox, or if you have any questions about these treatments, or simply would like to make an appointment, please feel free to contact us at</Typography>;
    return (
        <Card
            sx={{ minHeight: "90%" }}
            elevation={0}
        >
            <CardHeader
                title={title}
                subheader={<BusinessInfo />}
                sx={{ textAlign: "center" }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <div display="flex" width="100%" height="100%" flexDirection="column" overflow="hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3353.3553934201095!2d-117.2100397!3d32.80934719999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc00486a9c8b81%3A0x9313726421c14fb!2s3737+Moraga+Ave%2C+San+Diego%2C+CA+92117!5e0!3m2!1sen!2sus!4v140995469789"
                            flexGrow={1}
                            border="none"
                            margin={0}
                            padding={0}
                            width="100%"
                            height="600"
                            minHeight="400px">
                        </iframe>
                    </div>
                </CardContent>
            </Box>
        </Card>
    )
}